<template>
  <div id="wrapper">
    <b-navbar id="navbar" class="pl-5 pr-5" toggleable="md">
      <b-navbar-brand :to="localePath({ name: 'index' })">
        <img class="logo" src="@/assets/img/logo_frombea_dorado.png" />
      </b-navbar-brand>
      <b-navbar-toggle
        class="mt-3 mb-2 mt-md-0 mb-md-0"
        target="nav-collapse"
      ></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            :text="$t('Crea tu producto')"
            class="link-category d-none d-md-inline"
            toggle-class="nav-link-custom"
          >
            <div class="cats-wrapper">
              <div
                class="cat-wrapper"
                :key="cat1.element.id"
                v-for="cat1 in onlineMenuTree(null)"
              >
                <b-nav-item
                  class="cat-border-bottom"
                  :to="
                    localePath({
                      name: 'simulator-slug',
                      params: {
                        slug: getTranslatedKey(cat1.element, 'slug'),
                      },
                    })
                  "
                  >{{
                    cat1.element | getTranslatedKey('reference')
                  }}</b-nav-item
                >
                <div class="subcats-wrapper">
                  <div
                    class="subcat-wrapper"
                    :key="cat2.reference"
                    v-for="cat2 in cat1.children"
                  >
                    <b-nav-item
                      :to="
                        localePath({
                          name: 'simulator-slug-pslug',
                          params: {
                            pslug: getTranslatedKey(cat2.element, 'slug'),
                            slug: getTranslatedKey(cat1.element, 'slug'),
                          },
                        })
                      "
                      >{{
                        cat2.element | getTranslatedKey('reference')
                      }}</b-nav-item
                    >
                    <div class="subsubcats-wrapper">
                      <div
                        class="subsubcat-wrapper"
                        :key="cat3.reference"
                        v-for="cat3 in cat2.children"
                      >
                        <b-nav-item
                          :to="
                            localePath({
                              name: 'simulator-slug-pslug-ppslug',
                              params: {
                                ppslug: getTranslatedKey(cat3.element, 'slug'),
                                pslug: getTranslatedKey(cat2.element, 'slug'),
                                slug: getTranslatedKey(cat1.element, 'slug'),
                              },
                            })
                          "
                          >{{
                            cat3.element | getTranslatedKey('reference')
                          }}</b-nav-item
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            :text="$t('Nuestra colección')"
            class="link-category d-none d-md-inline"
            toggle-class="nav-link-custom"
          >
            <div class="cats-wrapper">
              <!-- Exclude Complementos -->
              <div
                class="cat-wrapper"
                :key="cat1.element.id"
                v-for="cat1 in offlineMenuTree(null).filter(
                  (x) => x.element.id !== 24
                )"
              >
                <b-nav-item
                  class="cat-border-bottom"
                  :to="
                    localePath({
                      name: 'catalog-slug',
                      params: {
                        slug: getTranslatedKey(cat1.element, 'slug'),
                      },
                    })
                  "
                  >{{
                    cat1.element | getTranslatedKey('reference')
                  }}</b-nav-item
                >
                <div class="subcats-wrapper">
                  <div
                    class="subcat-wrapper"
                    :key="cat2.reference"
                    v-for="cat2 in cat1.children"
                  >
                    <b-nav-item
                      :to="
                        localePath({
                          name: 'catalog-slug-pslug',
                          params: {
                            slug: getTranslatedKey(cat1.element, 'slug'),
                            pslug: getTranslatedKey(cat2.element, 'slug'),
                          },
                        })
                      "
                      >{{
                        cat2.element | getTranslatedKey('reference')
                      }}</b-nav-item
                    >
                    <div class="subsubcats-wrapper">
                      <div
                        class="subsubcat-wrapper"
                        :key="cat3.reference"
                        v-for="cat3 in cat2.children"
                      >
                        <b-nav-item
                          :to="
                            localePath({
                              name: 'catalog-slug-pslug-ppslug',
                              params: {
                                ppslug: getTranslatedKey(cat3.element, 'slug'),
                                pslug: getTranslatedKey(cat2.element, 'slug'),
                                slug: getTranslatedKey(cat1.element, 'slug'),
                              },
                            })
                          "
                          >{{
                            cat3.element | getTranslatedKey('reference')
                          }}</b-nav-item
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-nav-item-dropdown>

          <!-- Complementos (Desktop) -->
          <b-nav-item-dropdown
            :text="$t('Complementos')"
            class="link-category d-none d-md-inline"
            toggle-class="nav-link-custom"
          >
            <div class="cats-wrapper">
              <div
                class="cat-wrapper"
                :key="cat1.element.id"
                v-for="cat1 in offlineMenuTree(24)"
              >
                <b-nav-item
                  class="cat-border-bottom"
                  :to="
                    localePath({
                      name: 'catalog-slug-pslug',
                      params: {
                        slug: getTranslatedKey(catalogAccessoriesCategory, 'slug'),
                        pslug: getTranslatedKey(cat1.element, 'slug'),
                      },
                    })
                  "
                  >{{
                    cat1.element | getTranslatedKey('reference')
                  }}</b-nav-item
                >
              </div>
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav align="right" class="ml-auto">
          <b-nav-item-dropdown
            right
            no-caret
            class="d-none d-md-inline cart-wrapper"
          >
            <template slot="button-content">
              <b-avatar
                alt="Cesta"
                :class="[{ addCartEffect: addedToCartEffectEnabled }]"
                square
                size="32px"
                variant="default"
                icon="handbag"
                badge-top
                badge-offset="-0.5em"
                :badge="numCartItems"
              >
              </b-avatar>
            </template>
            <CartDropdown />
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            :text="$t('Nuestra colección')"
            class="link-category d-inline d-md-none"
            toggle-class="nav-link-custom"
            right
          >
            <div class="cats-wrapper">
              <!-- Exclude Complementos -->
              <div
                class="cat-wrapper"
                :key="cat1.element.id"
                v-for="cat1 in offlineMenuTree(null).filter(
                  (x) => x.element.id !== 24
                )"
              >
                <b-nav-item
                  class="cat-border-bottom"
                  :to="
                    localePath({
                      name: 'catalog-slug',
                      params: {
                        slug: getTranslatedKey(cat1.element, 'slug'),
                      },
                    })
                  "
                  >{{
                    cat1.element | getTranslatedKey('reference')
                  }}</b-nav-item
                >
                <div class="subcats-wrapper">
                  <div
                    class="subcat-wrapper"
                    :key="cat2.reference"
                    v-for="cat2 in cat1.children"
                  >
                    <b-nav-item
                      :to="
                        localePath({
                          name: 'catalog-slug-pslug',
                          params: {
                            slug: getTranslatedKey(cat1.element, 'slug'),
                            pslug: getTranslatedKey(cat2.element, 'slug'),
                          },
                        })
                      "
                      >{{
                        cat2.element | getTranslatedKey('reference')
                      }}</b-nav-item
                    >
                    <div class="subsubcats-wrapper">
                      <div
                        class="subsubcat-wrapper"
                        :key="cat3.reference"
                        v-for="cat3 in cat2.children"
                      >
                        <b-nav-item
                          :to="
                            localePath({
                              name: 'catalog-slug-pslug-ppslug',
                              params: {
                                ppslug: getTranslatedKey(cat3.element, 'slug'),
                                pslug: getTranslatedKey(cat2.element, 'slug'),
                                slug: getTranslatedKey(cat1.element, 'slug'),
                              },
                            })
                          "
                          >{{
                            cat3.element | getTranslatedKey('reference')
                          }}</b-nav-item
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-nav-item-dropdown>

          <!-- Complementos (mobile) -->
          <b-nav-item-dropdown
            :text="$t('Complementos')"
            class="link-category d-inline d-md-none"
            toggle-class="nav-link-custom"
            right
          >
            <div class="cats-wrapper">
              <div
                class="cat-wrapper"
                :key="cat1.element.id"
                v-for="cat1 in offlineMenuTree(24)"
              >
                <b-nav-item
                  class="cat-border-bottom"
                  :to="
                    localePath({
                      name: 'catalog-slug-pslug',
                      params: {
                        slug: getTranslatedKey(catalogAccessoriesCategory, 'slug'),
                        pslug: getTranslatedKey(cat1.element, 'slug'),
                      },
                    })
                  "
                  >{{
                    cat1.element | getTranslatedKey('reference')
                  }}</b-nav-item
                >
              </div>
            </div>
          </b-nav-item-dropdown>

          <b-nav-item
            v-if="!isAuthenticated"
            :to="localePath({ name: 'login' })"
            >{{ $t('Iniciar sesión') }}</b-nav-item
          >

          <b-nav-item :to="localePath({ name: 'contact' })">{{
            $t('Contacto')
          }}</b-nav-item>

          <b-nav-item-dropdown class="d-inline d-md-none" :text="$t('Idioma')">
            <b-dropdown-item :to="switchLocalePath('en')"
              >English</b-dropdown-item
            >
            <b-dropdown-item :to="switchLocalePath('es')"
              >Español</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            v-if="isAuthenticated"
            :text="$t('Mi cuenta')"
            right
          >
            <b-dropdown-item :to="localePath({ name: 'my-orders' })">{{
              $t('Mis pedidos')
            }}</b-dropdown-item>
            <b-dropdown-item :to="localePath({ name: 'user-my-info' })">{{
              $t('Mis datos')
            }}</b-dropdown-item>
            <b-dropdown-item :to="localePath({ name: 'logout' })">{{
              $t('Cerrar sesión')
            }}</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            class="d-none d-md-inline"
            :text="$t('Idioma')"
            right
          >
            <b-dropdown-item :to="switchLocalePath('en')"
              >English</b-dropdown-item
            >
            <b-dropdown-item :to="switchLocalePath('es')"
              >Español</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-navbar class="d-flex d-md-none">
      <b-navbar-nav>
        <b-nav-item-dropdown
          :text="$t('Crea tu producto')"
          class="link-category"
          toggle-class="nav-link-custom"
        >
          <div class="cats-wrapper">
            <div
              class="cat-wrapper"
              :key="cat1.element.id"
              v-for="cat1 in onlineMenuTree(null)"
            >
              <b-nav-item
                class="cat-border-bottom"
                :to="
                  localePath({
                    name: 'simulator-slug',
                    params: {
                      slug: getTranslatedKey(cat1.element, 'slug'),
                    },
                  })
                "
                >{{ cat1.element | getTranslatedKey('reference') }}</b-nav-item
              >
              <div class="subcats-wrapper">
                <div
                  class="subcat-wrapper"
                  :key="cat2.reference"
                  v-for="cat2 in cat1.children"
                >
                  <b-nav-item
                    :to="
                      localePath({
                        name: 'simulator-slug-pslug',
                        params: {
                          pslug: getTranslatedKey(cat2.element, 'slug'),
                          slug: getTranslatedKey(cat1.element, 'slug'),
                        },
                      })
                    "
                    >{{
                      cat2.element | getTranslatedKey('reference')
                    }}</b-nav-item
                  >
                  <div class="subsubcats-wrapper">
                    <div
                      class="subsubcat-wrapper"
                      :key="cat3.reference"
                      v-for="cat3 in cat2.children"
                    >
                      <b-nav-item
                        :to="
                          localePath({
                            name: 'simulator-slug-pslug-ppslug',
                            params: {
                              ppslug: getTranslatedKey(cat3.element, 'slug'),
                              pslug: getTranslatedKey(cat2.element, 'slug'),
                              slug: getTranslatedKey(cat1.element, 'slug'),
                            },
                          })
                        "
                        >{{
                          cat3.element | getTranslatedKey('reference')
                        }}</b-nav-item
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown
          class="position-static cart-wrapper"
          right
          no-caret
        >
          <template slot="button-content">
            <b-avatar
              alt="Cesta"
              :class="[{ addCartEffect: addedToCartEffectEnabled }]"
              square
              size="32px"
              variant="default"
              icon="handbag"
              badge-top
              badge-offset="-0.5em"
              :badge="numCartItems"
            >
            </b-avatar>
          </template>
          <CartDropdown />
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          class="d-none d-md-inline"
          :text="$t('Idioma')"
          right
        >
          <b-dropdown-item :to="switchLocalePath('en')"
            >English</b-dropdown-item
          >
          <b-dropdown-item :to="switchLocalePath('es')"
            >Español</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import CartDropdown from '@/components/CartDropdown.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Header',
  components: { CartDropdown },
  props: {},
  data() {
    return {
      categoryIDSelected: 0,
    };
  },

  methods: {
    onlineMenuTree(parent) {
      let roots = [];
      // let root = { children: [], element: null };
      for (let index = 0; index < this.getCategories.length; index++) {
        const element = this.getCategories[index];
        if (element.parent === parent) {
          let children = this.onlineMenuTree(element.id);
          if ((parent === null && children.length > 0) || parent !== null) {
            roots.push({
              children: children,
              element: {
                id: element.id,
                reference_es: element.reference_es,
                reference_en: element.reference_en,
                slug_es: element.slug_es,
                slug_en: element.slug_en,
              },
            });
          }
          // root.children = [...root.children, this.menuTree(element.id)];
          // root.element = {};
        }
      }

      return roots;
    },

    offlineMenuTree(parent) {
      let roots = [];
      // let root = { children: [], element: null };
      for (
        let index = 0;
        index < this.getPredefinedCategories.length;
        index++
      ) {
        const element = this.getPredefinedCategories[index];
        if (element.parent === parent) {
          let children = this.offlineMenuTree(element.id);
          if ((parent === null && children.length > 0) || parent !== null) {
            roots.push({
              children: children,
              element: {
                id: element.id,
                reference_es: element.reference_es,
                reference_en: element.reference_en,
                slug_es: element.slug_es,
                slug_en: element.slug_en,
              },
            });
          }
          // root.children = [...root.children, this.menuTree(element.id)];
          // root.element = {};
        }
      }

      return roots;
    },
  },
  computed: {
    ...mapGetters({
      getCategories: 'categories/getCategories',
      getPredefinedCategories: 'predefinedCategories/getPredefinedCategories',
      getCartItems: 'cart/getCartItems',
      getIsAuthenticated: 'auth/getIsAuthenticated',
      getCartEffectEnabled: 'cart/getCartEffectEnabled',
    }),
    numCartItems() {
      return this.getCartItems.length > 0
        ? this.getCartItems
            .reduce((prev, curr) => prev + curr.quantity, 0)
            .toString()
        : '0';
    },
    addedToCartEffectEnabled() {
      return this.getCartEffectEnabled;
    },
    isAuthenticated() {
      return this.getIsAuthenticated;
    },

    ropaDeMesaCategories() {
      return this.getCategories.filter(
        (x) => x.parent === 4
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    ropaDeMesaCategory() {
      return this.getCategories.find(
        (x) => x.id === 4
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    catalogIndividualesYServilletasCategory() {
      return this.getPredefinedCategories.find(
        (x) => x.id === 2
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
    catalogAccessoriesCategory() {
      return this.getPredefinedCategories.find(
        (x) => x.id === 24
        // && this.hasAnyProductIncludingChildren(x)
      );
    },
  },
};
</script>

<style lang="scss" src="@/assets/css/header.scss" scoped></style>
