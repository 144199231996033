import isEqual from 'lodash.isequal';

export const state = () => ({
  cartEffectEnabledOnce: false,
  addedToCartEffectEnabled: false,
  cart: {
    items: [],
    shipping_method: {
      id: null,
      name: null,
      cost: null,
    },
    overlay: false,
  },
});

export const mutations = {
  setCart(state, cart) {
    state.cart = cart;
  },
  setCartEffectEnabled(state, addedToCartEffectEnabled) {
    state.addedToCartEffectEnabled = addedToCartEffectEnabled;
  },
  setCartEffectEnabledOnce(state, cartEffectEnabledOnce) {
    state.cartEffectEnabledOnce = cartEffectEnabledOnce;
  },
  setCartOverlay(state, cartOverlay) {
    state.cart.overlay = cartOverlay;
  },
  setCartShipping(state, shippingData) {
    state.cart.shipping_method = shippingData;
  },
  delItemFromCart(state, index) {
    state.cart.items.splice(index, 1);
  },
  setCartItems(state, items) {
    state.cart.items = items;
  },
  addItemToCart(state, { productData, attributes, currentPrice, quantity }) {
    const pd = JSON.parse(JSON.stringify(productData));
    let att = JSON.parse(JSON.stringify(attributes));
    att = att.map(function (item) {
      delete item.selector_options;

      if (item.reference === 'BORDADO') {
        delete item.extra_info.color_options;
      }

      if (item.reference === 'INICIALES') {
        delete item.extra_info.color_options;
      }

      if ('initial_value' in item) {
        delete item.initial_value;
      }

      return item;
    });

    // TODO: sum +quantity if the product already exists on cart

    let foundIndex = state.cart.items.findIndex((x) => {
      let sameAttrs = isEqual(x.attributes, att);

      return (
        x.type === pd.type && x.id === pd.id,
        x.price === currentPrice && sameAttrs
      );
    });

    if (foundIndex >= 0) {
      state.cart.items[foundIndex].quantity += quantity;
    } else {
      state.cart.items.push({
        reference: pd.reference,

        attributes: att,
        quantity: quantity,
        type: pd.type,
        price: currentPrice,
        id: pd.id,
      });
    }
  },
  setCartItemQuantity(state, { index, quantity }) {
    state.cart.items[index].quantity = quantity;
  },
};

export const getters = {
  getCart(state) {
    return state.cart;
  },
  getCartEffectEnabled(state) {
    return state.addedToCartEffectEnabled;
  },
  getCartEffectEnabledOnce(state) {
    return state.cartEffectEnabledOnce;
  },
  getCartOverlay(state) {
    return state.cart.overlay;
  },
  getCartShipping(state) {
    return state.cart.shipping_method;
  },
  getCartItems(state) {
    return state.cart.items;
  },
};
