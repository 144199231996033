<template>
  <div id="wrapper">
    <b-container fluid class="p-5">
      <b-row>
        <b-col cols="12" md="6">
          <span
            >© {{ currentYear }} from:BEA –
            {{ $t('Todos los derechos reservados') }}</span
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="mt-2">
            <router-link
              class="primary-color"
              :to="localePath({ name: 'privacy-policy' })"
              >{{ $t('Política de privacidad y aviso legal') }}</router-link
            >&nbsp;|
            <router-link
              class="primary-color"
              :to="localePath({ name: 'cookie-policy' })"
              >{{ $t('Política de cookies') }}</router-link
            >&nbsp;|
            <router-link
              class="primary-color"
              :to="localePath({ name: 'terms-conditions' })"
              >{{ $t('Términos y condiciones') }}</router-link
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CookiePolicyContent from '@/components/CookiePolicyContent.vue';

export default {
  name: 'Footer',
  components: {
    CookiePolicyContent,
  },
  data() {
    return {};
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {},
};
</script>

<style lang="scss" src="@/assets/css/footer.scss" scoped></style>
