import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ea235ef = () => interopDefault(import('../src/pages/campaigns.vue' /* webpackChunkName: "pages/campaigns" */))
const _8cba3a2c = () => interopDefault(import('../src/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _0ce3cab6 = () => interopDefault(import('../src/pages/checkout-finished.vue' /* webpackChunkName: "pages/checkout-finished" */))
const _479cdf2c = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _32571b47 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _da427044 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0ef082de = () => interopDefault(import('../src/pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _5c866af5 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _fd5007a4 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _791e4132 = () => interopDefault(import('../src/pages/my-orders.vue' /* webpackChunkName: "pages/my-orders" */))
const _e31a070c = () => interopDefault(import('../src/pages/fabrics.vue' /* webpackChunkName: "pages/fabrics" */))
const _0277ef3b = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _abbeb9ec = () => interopDefault(import('../src/pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _59053c62 = () => interopDefault(import('../src/pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _72369cef = () => interopDefault(import('../src/pages/user.vue' /* webpackChunkName: "pages/user" */))
const _a35567de = () => interopDefault(import('../src/pages/user/my-addresses.vue' /* webpackChunkName: "pages/user/my-addresses" */))
const _341d270f = () => interopDefault(import('../src/pages/user/my-info.vue' /* webpackChunkName: "pages/user/my-info" */))
const _6ab5b696 = () => interopDefault(import('../src/pages/catalog/product/_pslug.vue' /* webpackChunkName: "pages/catalog/product/_pslug" */))
const _4b19b085 = () => interopDefault(import('../src/pages/change-email/confirm/_token.vue' /* webpackChunkName: "pages/change-email/confirm/_token" */))
const _583e28a1 = () => interopDefault(import('../src/pages/register/confirm/_token.vue' /* webpackChunkName: "pages/register/confirm/_token" */))
const _47a8b956 = () => interopDefault(import('../src/pages/cart/_token.vue' /* webpackChunkName: "pages/cart/_token" */))
const _38c8faa3 = () => interopDefault(import('../src/pages/catalog/_slug/index.vue' /* webpackChunkName: "pages/catalog/_slug/index" */))
const _7572e576 = () => interopDefault(import('../src/pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _bf95f308 = () => interopDefault(import('../src/pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _957e48e4 = () => interopDefault(import('../src/pages/simulator/_slug/index.vue' /* webpackChunkName: "pages/simulator/_slug/index" */))
const _33aca044 = () => interopDefault(import('../src/pages/catalog/_slug/_pslug/index.vue' /* webpackChunkName: "pages/catalog/_slug/_pslug/index" */))
const _28ca0093 = () => interopDefault(import('../src/pages/simulator/_slug/_pslug/index.vue' /* webpackChunkName: "pages/simulator/_slug/_pslug/index" */))
const _631964e4 = () => interopDefault(import('../src/pages/simulator/_slug/_pslug/_ppslug/index.vue' /* webpackChunkName: "pages/simulator/_slug/_pslug/_ppslug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campanas",
    component: _2ea235ef,
    name: "campaigns___es"
  }, {
    path: "/checkout",
    component: _8cba3a2c,
    name: "checkout___es"
  }, {
    path: "/checkout-finished",
    component: _0ce3cab6,
    name: "checkout-finished___es"
  }, {
    path: "/contacto",
    component: _479cdf2c,
    name: "contact___es"
  }, {
    path: "/cookie-policy",
    component: _32571b47,
    name: "cookie-policy___es"
  }, {
    path: "/en",
    component: _da427044,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _0ef082de,
    name: "forgot-password___es"
  }, {
    path: "/login",
    component: _5c866af5,
    name: "login___es"
  }, {
    path: "/logout",
    component: _fd5007a4,
    name: "logout___es"
  }, {
    path: "/my-orders",
    component: _791e4132,
    name: "my-orders___es"
  }, {
    path: "/nuestras-telas",
    component: _e31a070c,
    name: "fabrics___es"
  }, {
    path: "/privacy-policy",
    component: _0277ef3b,
    name: "privacy-policy___es"
  }, {
    path: "/register",
    component: _abbeb9ec,
    name: "register___es"
  }, {
    path: "/terms-conditions",
    component: _59053c62,
    name: "terms-conditions___es"
  }, {
    path: "/user",
    component: _72369cef,
    name: "user___es",
    children: [{
      path: "my-addresses",
      component: _a35567de,
      name: "user-my-addresses___es"
    }, {
      path: "my-info",
      component: _341d270f,
      name: "user-my-info___es"
    }]
  }, {
    path: "/en/campaigns",
    component: _2ea235ef,
    name: "campaigns___en"
  }, {
    path: "/en/checkout",
    component: _8cba3a2c,
    name: "checkout___en"
  }, {
    path: "/en/checkout-finished",
    component: _0ce3cab6,
    name: "checkout-finished___en"
  }, {
    path: "/en/contact",
    component: _479cdf2c,
    name: "contact___en"
  }, {
    path: "/en/cookie-policy",
    component: _32571b47,
    name: "cookie-policy___en"
  }, {
    path: "/en/forgot-password",
    component: _0ef082de,
    name: "forgot-password___en"
  }, {
    path: "/en/login",
    component: _5c866af5,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _fd5007a4,
    name: "logout___en"
  }, {
    path: "/en/my-orders",
    component: _791e4132,
    name: "my-orders___en"
  }, {
    path: "/en/our-fabrics",
    component: _e31a070c,
    name: "fabrics___en"
  }, {
    path: "/en/privacy-policy",
    component: _0277ef3b,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _abbeb9ec,
    name: "register___en"
  }, {
    path: "/en/terms-conditions",
    component: _59053c62,
    name: "terms-conditions___en"
  }, {
    path: "/en/user",
    component: _72369cef,
    name: "user___en",
    children: [{
      path: "my-addresses",
      component: _a35567de,
      name: "user-my-addresses___en"
    }, {
      path: "my-info",
      component: _341d270f,
      name: "user-my-info___en"
    }]
  }, {
    path: "/en/catalog/product/:pslug?",
    component: _6ab5b696,
    name: "catalog-product-pslug___en"
  }, {
    path: "/en/change-email/confirm/:token?",
    component: _4b19b085,
    name: "change-email-confirm-token___en"
  }, {
    path: "/en/register/confirm/:token?",
    component: _583e28a1,
    name: "register-confirm-token___en"
  }, {
    path: "/catalogo/producto/:pslug?",
    component: _6ab5b696,
    name: "catalog-product-pslug___es"
  }, {
    path: "/change-email/confirm/:token?",
    component: _4b19b085,
    name: "change-email-confirm-token___es"
  }, {
    path: "/en/cart/:token?",
    component: _47a8b956,
    name: "cart-token___en"
  }, {
    path: "/en/catalog/:slug",
    component: _38c8faa3,
    name: "catalog-slug___en"
  }, {
    path: "/en/product/:slug?",
    component: _7572e576,
    name: "product-slug___en"
  }, {
    path: "/en/reset-password/:token?",
    component: _bf95f308,
    name: "reset-password-token___en"
  }, {
    path: "/en/simulator/:slug",
    component: _957e48e4,
    name: "simulator-slug___en"
  }, {
    path: "/register/confirm/:token?",
    component: _583e28a1,
    name: "register-confirm-token___es"
  }, {
    path: "/en/catalog/:slug/:pslug",
    component: _33aca044,
    name: "catalog-slug-pslug___en"
  }, {
    path: "/en/simulator/:slug/:pslug",
    component: _28ca0093,
    name: "simulator-slug-pslug___en"
  }, {
    path: "/en/simulator/:slug/:pslug/:ppslug",
    component: _631964e4,
    name: "simulator-slug-pslug-ppslug___en"
  }, {
    path: "/cart/:token?",
    component: _47a8b956,
    name: "cart-token___es"
  }, {
    path: "/catalogo/:slug",
    component: _38c8faa3,
    name: "catalog-slug___es"
  }, {
    path: "/producto/:slug?",
    component: _7572e576,
    name: "product-slug___es"
  }, {
    path: "/reset-password/:token?",
    component: _bf95f308,
    name: "reset-password-token___es"
  }, {
    path: "/simulador/:slug",
    component: _957e48e4,
    name: "simulator-slug___es"
  }, {
    path: "/catalogo/:slug/:pslug",
    component: _33aca044,
    name: "catalog-slug-pslug___es"
  }, {
    path: "/simulador/:slug/:pslug",
    component: _28ca0093,
    name: "simulator-slug-pslug___es"
  }, {
    path: "/simulador/:slug/:pslug/:ppslug",
    component: _631964e4,
    name: "simulator-slug-pslug-ppslug___es"
  }, {
    path: "/",
    component: _da427044,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
