// import axiosVue from '@/axiosVue.js';

import axios from 'axios';

import * as Sentry from '@sentry/browser';

export default function ({ app, store }, inject) {
  const api = axios.create({
    baseURL: app.$config.baseUrl,
    headers: {},
    defaults: {
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFTOKEN',
    },
  });

  api.interceptors.request.use((req) => {
    let isAuthenticated = store.getters['auth/getIsAuthenticated'];
    let getToken = store.getters['auth/getToken'];

    if (isAuthenticated) {
      req.headers.common['Authorization'] = 'Token ' + getToken;
    }

    // Sentry.setExtra('request', req);
    return req;
  });

  let that = this;
  api.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.commit('auth/setAuth', {
          isAuthenticated: false,
          token: null,
          type: null,
          business_type: null,
        });

        app.$cookiez.remove('token');
      }
      if (error.response.status === 400) {
        Sentry.setExtra('response', error);
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      return Promise.reject(error);
    }
  );

  const actions = {
    /* Products */
    getAllProducts: function () {
      return api.get(uris.productsURI);
    },
    getOnlineProducts: function () {
      return api.get(uris.productsURI);
    },
    getProduct: function (id) {
      return api.get(uris.productURI(id));
    },
    getProductExtended: function (id) {
      return api.get(uris.productURI(id) + 'extended/');
    },
    getProductTemplate: function (reference) {
      return api.get(uris.productTemplateURI(reference));
    },
    getProductSelector: function (reference) {
      return api.get(uris.productSelectorURI(reference));
    },

    getPredefinedProduct: function (id) {
      return api.get(uris.predefinedProductURI(id));
    },
    getPredefinedProductExtended: function (id) {
      return api.get(uris.predefinedProductURI(id) + 'extended/');
    },

    getColorsUnpaginated: function () {
      return api.get(uris.colorsUnpaginatedURI);
    },

    getEmbroideryCategoriesUnpaginated: function () {
      return api.get(uris.embroideryCategoriesUnpaginatedURI);
    },

    getStandardEmbroideriesUnpaginated: function () {
      return api.get(uris.standardEmbroideriesUnpaginatedURI);
    },

    getEmbroiderySettingsUnpaginated: function () {
      return api.get(uris.embroiderySettingsUnpaginatedURI);
    },

    getInitial: function (reference) {
      return api.get(uris.initialURI(reference));
    },
    postProductQuotePrice: function (reference, data) {
      return api.post(uris.productQuotePriceURI(reference), data);
    },

    getFabric: function (reference) {
      return api.get(uris.fabricURI(reference));
    },

    getFabrics: function () {
      return api.get(uris.fabricsURI());
    },

    getFabricsOurFabrics() {
      return api.get(uris.fabricsOurFabricsURI());
    },

    /* fabriccompositions */
    getFabricCompositions: function () {
      return api.get(uris.fabricCompositionsUnpaginatedURI);
    },

    /* fabriccolorfamilies */
    getFabricColorFamilies: function () {
      return api.get(uris.fabricColorFamiliesUnpaginatedURI);
    },

    /* fabricdesigns */
    getFabricDesigns: function () {
      return api.get(uris.fabricDesignsUnpaginatedURI);
    },

    getStitch: function (reference) {
      return api.get(uris.stitchURI(reference));
    },

    getColor: function (reference) {
      return api.get(uris.colorURI(reference));
    },

    postCustomer: function (data) {
      return api.post(uris.customersURI, data);
    },

    postCustomerVerify: function (data) {
      return api.post(uris.customersVerifyURI, data);
    },

    postAuth: function (data) {
      return api.post('store/auth/', data);
    },
    getAuth: function (token) {
      return api.get('store/auth/', {
        headers: {
          Authorization: 'Token ' + token,
        },
      });
    },
    downloadOrder: function (id) {
      return api.get(uris.orderDownloadURI(id), {
        responseType: 'arraybuffer',
      });
    },

    downloadOrderLegal: function (id) {
      return api.get(uris.orderLegalDownloadURI(id), {
        responseType: 'arraybuffer',
      });
    },

    getCustomer: function (id) {
      return api.get(uris.customerURI(id));
    },

    patchCustomer: function (id, data) {
      return api.patch(uris.customerURI(id), data);
    },

    postCustomerChangeEmailVerify: function (data) {
      return api.post(uris.customerChangeEmailVerifyURI, data);
    },

    postCustomerResetPassword: function (data) {
      return api.post(uris.customerResetPasswordURI, data);
    },

    postCustomerSetPassword: function (data) {
      return api.post(uris.customerSetPasswordURI, data);
    },

    getBillingAddressesByCustomer: function (customerID) {
      return api.get(uris.billingAddressesByCustomerURI(customerID, 0));
    },

    getShippingAddressesByCustomer: function (customerID) {
      return api.get(uris.shippingAddressesByCustomerURI(customerID, 0));
    },

    getCountriesUnpaginated: function () {
      return api.get(uris.countriesUnpaginatedURI);
    },

    postBillingAddress: function (data) {
      return api.post(uris.billingAddressesURI, data);
    },
    patchBillingAddress: function (id, data) {
      return api.patch(uris.billingAddressURI(id), data);
    },
    postShippingAddress: function (data) {
      return api.post(uris.shippingAddressesURI, data);
    },
    patchShippingAddress: function (reference, data) {
      return api.patch(uris.shippingAddressURI(reference), data);
    },

    deleteBillingAddress: function (id) {
      return api.delete(uris.billingAddressURI(id));
    },
    deleteShippingAddress: function (reference) {
      return api.delete(uris.shippingAddressURI(reference));
    },

    getShippingMethodsUnpaginated: function () {
      return api.get(uris.shippingMethodsUnpaginatedURI);
    },

    postCheckoutGetShippingPrice: function (data) {
      return api.post(uris.checkoutGetShippingPriceURI, data);
    },

    postCheckoutNewOrder: function (data) {
      return api.post(uris.checkoutNewOrderURI, data);
    },

    getProductCategoriesUnpaginated: function () {
      return api.get(uris.productCategoriesUnpaginatedURI);
    },

    getProductCategoriesByParentUnpaginated: function (id) {
      return api.get(uris.productCategoriesbyParentUnpaginatedURI(id));
    },

    getProductCategoriesByID: function (id) {
      return api.get(uris.productCategoriesbyIDURI(id));
    },

    getPredefinedProductCategoriesUnpaginated: function () {
      return api.get(uris.predefinedProductCategoriesUnpaginatedURI);
    },

    getPredefinedProductCategoriesByParentUnpaginated: function (id) {
      return api.get(
        uris.predefinedProductCategoriesbyParentUnpaginatedURI(id)
      );
    },

    getPredefinedProductCategoriesByID: function (id) {
      return api.get(uris.predefinedProductCategoriesbyIDURI(id));
    },

    postSharedCart: function (data) {
      return api.post(uris.sharedCartsURI, data);
    },

    getSharedCartByToken: function (token) {
      return api.get(uris.sharedCartByTokenURI(token));
    },

    // postArrangeCall: function (data) {
    //   return api.post(uris.arrangeCallURI, data);
    // },

    getOrders: function () {
      return api.get(uris.ordersURI);
    },

    // Carts
    postCart: function (data) {
      return api.post(uris.cartsURI, data);
    },

    patchCart: function (id, data) {
      return api.patch(uris.cartURI(id), data);
    },

    getCart: function (id) {
      return api.get(uris.cartURI(id));
    },

    // Web

    getWebHomeSettings: function (data) {
      return api.get(uris.webHomeSettingsURI);
    },
    // postCheckoutGetShippingMethods: function (data) {
    //   return api.post(uris.checkoutGetShippingMethodsURI, data);
    // },

    // getCategories: function () {
    //   return axiosVue.get('categories/');
    // },
    // getBusinessInfo: function () {
    //   return axiosVue.get('business_info/');
    // },
    // getOpeningHours: function () {
    //   return axiosVue.get('opening_hours/');
    // },
    // getOrderSettings: function () {
    //   return axiosVue.get('order_settings/');
    // },
    // postPayment: function (data) {
    //   return axiosVue.post('payments/', data);
    // },
    // postCheckCoupon: function (data) {
    //   return axiosVue.post('payments/check_coupon/', data);
    // },
    // postCalculateCart: function (data) {
    //   return axiosVue.post('payments/calculate_cart/', data);
    // },
    // getLegal: function () {
    //   return axiosVue.get('legal/');
    // },
    // getDesign: function () {
    //   return axiosVue.get('design/');
    // },
    // getRestaurant: function () {
    //   return axiosVue.get('restaurant/');
    // },
  };

  const uris = {
    productsURI: 'store/products/',
    productURI: (id) => {
      return `store/products/${id}/`;
    },
    predefinedProductURI: (id) => {
      return `store/predefinedproducts/${id}/`;
    },
    productTemplateURI: (reference) => {
      return `store/producttemplates/${reference}/`;
    },
    productSelectorURI: (reference) => {
      return `store/productselectors/${reference}/`;
    },
    colorsUnpaginatedURI: 'store/colors/?page_size=9999',
    embroideryCategoriesUnpaginatedURI:
      'store/embroiderycategories/?page_size=9999',
    standardEmbroideriesUnpaginatedURI:
      'store/standardembroideries/?page_size=9999',
    embroiderySettingsUnpaginatedURI:
      'store/embroiderysettings/?page_size=9999',
    initialURI: (id) => {
      return `store/initials/${id}/`;
    },
    productQuotePriceURI: (reference) => {
      return `store/products/${reference}/quote_price/`;
    },
    fabricsURI: () => {
      return `store/fabrics/?page_size=9999`;
    },
    fabricsOurFabricsURI: () => {
      return `store/fabrics/?page_size=9999&show_our_fabrics=1`;
    },

    /* fabriccompositions */
    fabricCompositionsUnpaginatedURI:
      'store/fabriccompositions/?page_size=9999',

    /* fabriccolorfamilies */
    fabricColorFamiliesUnpaginatedURI:
      'store/fabriccolorfamilies/?page_size=9999',

    /* fabricdesigns */
    fabricDesignsUnpaginatedURI: 'store/fabricdesigns/?page_size=9999',

    fabricURI: (id) => {
      return `store/fabrics/${id}/`;
    },
    stitchURI: (reference) => {
      return `store/stitches/${reference}/`;
    },
    colorURI: (id) => {
      return `store/colors/${id}/`;
    },
    customersURI: 'store/customers/',
    customersVerifyURI: 'store/customers/confirm/',
    ordersURI: 'store/orders/',
    orderDownloadURI: (id) => {
      return `store/orders/${id}/download/`;
    },
    orderLegalDownloadURI: (id) => {
      return `store/orders/${id}/download_legal/`;
    },
    customerURI: (reference) => {
      return `store/customers/${reference}/`;
    },
    customerChangeEmailVerifyURI: 'store/customers/confirm_email/',
    customerResetPasswordURI: 'store/customers/reset_password/',
    customerSetPasswordURI: 'store/customers/confirm_reset_password/',
    billingAddressesByCustomerURI: (customerID, hidden) => {
      return `store/billingaddresses/?customer_id=${customerID}&hidden=${hidden}`;
    },
    shippingAddressesByCustomerURI: (customerID, hidden) => {
      return `store/shippingaddresses/?customer_id=${customerID}&hidden=${hidden}`;
    },
    countriesUnpaginatedURI: 'store/countries/?page_size=9999',
    billingAddressesURI: 'store/billingaddresses/',
    billingAddressURI: (id) => {
      return `store/billingaddresses/${id}/`;
    },
    shippingAddressesURI: 'store/shippingaddresses/',
    shippingAddressURI: (reference) => {
      return `store/shippingaddresses/${reference}/`;
    },
    shippingMethodsUnpaginatedURI: 'store/shippingmethods/?page_size=9999',
    checkoutGetShippingPriceURI: 'store/checkout/get_shipping_price/',
    checkoutNewOrderURI: 'store/checkout/new_order/',
    // checkoutGetShippingMethodsURI: 'store/checkout/get_shipping_methods/',
    productCategoriesUnpaginatedURI: 'store/productcategories/?page_size=9999',
    productCategoriesbyParentUnpaginatedURI: (id) => {
      return `store/productcategories/?parent=${id}&page_size=9999`;
    },
    productCategoriesbyIDURI: (id) => {
      return `store/productcategories/?id=${id}`;
    },
    predefinedProductCategoriesUnpaginatedURI:
      'store/predefinedproductcategories/?page_size=9999',
    predefinedProductCategoriesbyParentUnpaginatedURI: (id) => {
      return `store/predefinedproductcategories/?parent=${id}&page_size=9999`;
    },
    predefinedProductCategoriesbyIDURI: (id) => {
      return `store/predefinedproductcategories/?id=${id}`;
    },
    sharedCartsURI: 'store/sharedcarts/',
    sharedCartByTokenURI: (token) => {
      return `store/sharedcarts/${token}`;
    },
    // arrangeCallURI: 'store/arrangecall/',
    cartsURI: 'store/carts/',
    cartURI: (id) => {
      return `store/carts/${id}/`;
    },

    //  Web
    webHomeSettingsURI: 'store/webhomesettings/0/',
  };

  inject('actions', actions);
  inject('uris', uris);
  inject('api', api);
}

// export default api;
