export const state = () => ({
  predefinedCategories: [],
});

export const mutations = {
  setPredefinedCategories(state, predefinedCategories) {
    state.predefinedCategories = predefinedCategories;
  },
};

export const getters = {
  getPredefinedCategories(state) {
    return state.predefinedCategories;
  },
};

export const actions = {};
