export const state = () => ({
  webHomeSettings: {},
});

export const mutations = {
  setWebHomeSettings(state, webHomeSettings) {
    state.webHomeSettings = webHomeSettings;
  },
};

export const getters = {
  getWebHomeSettings(state) {
    return state.webHomeSettings;
  },
};

export const actions = {};
