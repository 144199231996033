export const state = () => ({
  auth: {
    isAuthenticated: false,
    token: false,
    type: null,
    business_type: null,
  },
});

export const mutations = {
  setAuth(state, { isAuthenticated, token, type, business_type }) {
    state.auth.isAuthenticated = isAuthenticated;
    state.auth.token = token;
    state.auth.type = type;
    state.auth.business_type = business_type;
  },
};

export const getters = {
  getAuth(state) {
    return state.auth;
  },
  getIsAuthenticated(state) {
    return state.auth.isAuthenticated;
  },
  getToken(state) {
    return state.auth.token;
  },
};
