export const actions = {
  async nuxtServerInit({ commit }, { app }) {
    const promisePC = await app.$actions.getProductCategoriesUnpaginated();
    commit('categories/setCategories', promisePC.data.results);

    const promisePPC =
      await app.$actions.getPredefinedProductCategoriesUnpaginated();
    commit(
      'predefinedCategories/setPredefinedCategories',
      promisePPC.data.results
    );

    const promiseProducts = await app.$actions.getOnlineProducts();
    commit('products/setProducts', promiseProducts.data.results);

    const promiseWHS = await app.$actions.getWebHomeSettings();
    commit('webHomeSettings/setWebHomeSettings', promiseWHS.data);
  },
};
