export default function (to, from, savedPosition) {
  let nuxt = window.$nuxt;

  let fromName = from.name.replace('___es', '').replace('___en', '');
  let toName = to.name.replace('___es', '').replace('___en', '');

  let position = savedPosition;
  let positionTop = { x: 0, y: 0 };

  // From home to static pages
  if (
    fromName === 'index' &&
    [
      'fabrics',
      'contact',
      'terms-conditions',
      'privacy-policy',
      'cookie-policy',
    ].includes(toName)
  ) {
    position = positionTop;
  }

  // From home to any product category
  // Online
  if (
    fromName === 'index' &&
    [
      'simulator-slug',
      'simulator-slug-pslug',
      'simulator-slug-pslug-ppslug',
    ].includes(toName)
  ) {
    position = positionTop;
  }

  // From home to any product category
  // Offline
  if (
    fromName === 'index' &&
    ['catalog-slug', 'catalog-slug-pslug'].includes(toName)
  ) {
    position = positionTop;
  }

  // From category to sub-category and vice-versa
  // Online
  if (
    (fromName === 'simulator-slug' && toName === 'simulator-slug-pslug') ||
    (fromName === 'simulator-slug-pslug' && toName === 'simulator-slug') ||
    (fromName === 'simulator-slug-pslug' &&
      toName === 'simulator-slug-pslug-ppslug') ||
    (fromName === 'simulator-slug-pslug-ppslug' &&
      toName === 'simulator-slug-pslug')
  ) {
    //  nothing
  }

  // Offline
  if (
    (fromName === 'catalog-slug' && toName === 'catalog-slug-pslug') ||
    (fromName === 'catalog-slug-pslug' && toName === 'catalog-slug')
  ) {
    //  nothing
  }

  // From any category to simulator page
  // Online
  if (
    [
      'simulator-slug',
      'simulator-slug-pslug',
      'simulator-slug-pslug-ppslug',
    ].includes(fromName) &&
    toName === 'product-slug'
  ) {
    position = positionTop;
  }

  // Offline
  if (
    [
      'catalog-slug',
      'catalog-slug-pslug',
      'catalog-slug-pslug-ppslug',
    ].includes(fromName) &&
    toName === 'catalog-product-pslug'
  ) {
    position = positionTop;
  }

  return new Promise((resolve) => {
    nuxt.$root.$once('page-after-leave', () => {
      nuxt.$nextTick(() => {
        resolve(position !== null ? position : false);
      });
    });
  });
}
