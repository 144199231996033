var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrapper"}},[_c('b-navbar',{staticClass:"pl-5 pr-5",attrs:{"id":"navbar","toggleable":"md"}},[_c('b-navbar-brand',{attrs:{"to":_vm.localePath({ name: 'index' })}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo_frombea_dorado.png")}})]),_vm._v(" "),_c('b-navbar-toggle',{staticClass:"mt-3 mb-2 mt-md-0 mb-md-0",attrs:{"target":"nav-collapse"}}),_vm._v(" "),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item-dropdown',{staticClass:"link-category d-none d-md-inline",attrs:{"text":_vm.$t('Crea tu producto'),"toggle-class":"nav-link-custom"}},[_c('div',{staticClass:"cats-wrapper"},_vm._l((_vm.onlineMenuTree(null)),function(cat1){return _c('div',{key:cat1.element.id,staticClass:"cat-wrapper"},[_c('b-nav-item',{staticClass:"cat-border-bottom",attrs:{"to":_vm.localePath({
                    name: 'simulator-slug',
                    params: {
                      slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                    },
                  })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat1.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subcats-wrapper"},_vm._l((cat1.children),function(cat2){return _c('div',{key:cat2.reference,staticClass:"subcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                        name: 'simulator-slug-pslug',
                        params: {
                          pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                          slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                        },
                      })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat2.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subsubcats-wrapper"},_vm._l((cat2.children),function(cat3){return _c('div',{key:cat3.reference,staticClass:"subsubcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                            name: 'simulator-slug-pslug-ppslug',
                            params: {
                              ppslug: _vm.getTranslatedKey(cat3.element, 'slug'),
                              pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                              slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                            },
                          })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat3.element,'reference')))])],1)}),0)],1)}),0)],1)}),0)]),_vm._v(" "),_c('b-nav-item-dropdown',{staticClass:"link-category d-none d-md-inline",attrs:{"text":_vm.$t('Nuestra colección'),"toggle-class":"nav-link-custom"}},[_c('div',{staticClass:"cats-wrapper"},_vm._l((_vm.offlineMenuTree(null).filter(
                (x) => x.element.id !== 24
              )),function(cat1){return _c('div',{key:cat1.element.id,staticClass:"cat-wrapper"},[_c('b-nav-item',{staticClass:"cat-border-bottom",attrs:{"to":_vm.localePath({
                    name: 'catalog-slug',
                    params: {
                      slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                    },
                  })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat1.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subcats-wrapper"},_vm._l((cat1.children),function(cat2){return _c('div',{key:cat2.reference,staticClass:"subcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                        name: 'catalog-slug-pslug',
                        params: {
                          slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                          pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                        },
                      })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat2.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subsubcats-wrapper"},_vm._l((cat2.children),function(cat3){return _c('div',{key:cat3.reference,staticClass:"subsubcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                            name: 'catalog-slug-pslug-ppslug',
                            params: {
                              ppslug: _vm.getTranslatedKey(cat3.element, 'slug'),
                              pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                              slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                            },
                          })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat3.element,'reference')))])],1)}),0)],1)}),0)],1)}),0)]),_vm._v(" "),_c('b-nav-item-dropdown',{staticClass:"link-category d-none d-md-inline",attrs:{"text":_vm.$t('Complementos'),"toggle-class":"nav-link-custom"}},[_c('div',{staticClass:"cats-wrapper"},_vm._l((_vm.offlineMenuTree(24)),function(cat1){return _c('div',{key:cat1.element.id,staticClass:"cat-wrapper"},[_c('b-nav-item',{staticClass:"cat-border-bottom",attrs:{"to":_vm.localePath({
                    name: 'catalog-slug-pslug',
                    params: {
                      slug: _vm.getTranslatedKey(_vm.catalogAccessoriesCategory, 'slug'),
                      pslug: _vm.getTranslatedKey(cat1.element, 'slug'),
                    },
                  })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat1.element,'reference')))])],1)}),0)])],1),_vm._v(" "),_c('b-navbar-nav',{staticClass:"ml-auto",attrs:{"align":"right"}},[_c('b-nav-item-dropdown',{staticClass:"d-none d-md-inline cart-wrapper",attrs:{"right":"","no-caret":""}},[_c('template',{slot:"button-content"},[_c('b-avatar',{class:[{ addCartEffect: _vm.addedToCartEffectEnabled }],attrs:{"alt":"Cesta","square":"","size":"32px","variant":"default","icon":"handbag","badge-top":"","badge-offset":"-0.5em","badge":_vm.numCartItems}})],1),_vm._v(" "),_c('CartDropdown')],2),_vm._v(" "),_c('b-nav-item-dropdown',{staticClass:"link-category d-inline d-md-none",attrs:{"text":_vm.$t('Nuestra colección'),"toggle-class":"nav-link-custom","right":""}},[_c('div',{staticClass:"cats-wrapper"},_vm._l((_vm.offlineMenuTree(null).filter(
                (x) => x.element.id !== 24
              )),function(cat1){return _c('div',{key:cat1.element.id,staticClass:"cat-wrapper"},[_c('b-nav-item',{staticClass:"cat-border-bottom",attrs:{"to":_vm.localePath({
                    name: 'catalog-slug',
                    params: {
                      slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                    },
                  })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat1.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subcats-wrapper"},_vm._l((cat1.children),function(cat2){return _c('div',{key:cat2.reference,staticClass:"subcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                        name: 'catalog-slug-pslug',
                        params: {
                          slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                          pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                        },
                      })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat2.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subsubcats-wrapper"},_vm._l((cat2.children),function(cat3){return _c('div',{key:cat3.reference,staticClass:"subsubcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                            name: 'catalog-slug-pslug-ppslug',
                            params: {
                              ppslug: _vm.getTranslatedKey(cat3.element, 'slug'),
                              pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                              slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                            },
                          })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat3.element,'reference')))])],1)}),0)],1)}),0)],1)}),0)]),_vm._v(" "),_c('b-nav-item-dropdown',{staticClass:"link-category d-inline d-md-none",attrs:{"text":_vm.$t('Complementos'),"toggle-class":"nav-link-custom","right":""}},[_c('div',{staticClass:"cats-wrapper"},_vm._l((_vm.offlineMenuTree(24)),function(cat1){return _c('div',{key:cat1.element.id,staticClass:"cat-wrapper"},[_c('b-nav-item',{staticClass:"cat-border-bottom",attrs:{"to":_vm.localePath({
                    name: 'catalog-slug-pslug',
                    params: {
                      slug: _vm.getTranslatedKey(_vm.catalogAccessoriesCategory, 'slug'),
                      pslug: _vm.getTranslatedKey(cat1.element, 'slug'),
                    },
                  })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat1.element,'reference')))])],1)}),0)]),_vm._v(" "),(!_vm.isAuthenticated)?_c('b-nav-item',{attrs:{"to":_vm.localePath({ name: 'login' })}},[_vm._v(_vm._s(_vm.$t('Iniciar sesión')))]):_vm._e(),_vm._v(" "),_c('b-nav-item',{attrs:{"to":_vm.localePath({ name: 'contact' })}},[_vm._v(_vm._s(_vm.$t('Contacto')))]),_vm._v(" "),_c('b-nav-item-dropdown',{staticClass:"d-inline d-md-none",attrs:{"text":_vm.$t('Idioma')}},[_c('b-dropdown-item',{attrs:{"to":_vm.switchLocalePath('en')}},[_vm._v("English")]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":_vm.switchLocalePath('es')}},[_vm._v("Español")])],1),_vm._v(" "),(_vm.isAuthenticated)?_c('b-nav-item-dropdown',{attrs:{"text":_vm.$t('Mi cuenta'),"right":""}},[_c('b-dropdown-item',{attrs:{"to":_vm.localePath({ name: 'my-orders' })}},[_vm._v(_vm._s(_vm.$t('Mis pedidos')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":_vm.localePath({ name: 'user-my-info' })}},[_vm._v(_vm._s(_vm.$t('Mis datos')))]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":_vm.localePath({ name: 'logout' })}},[_vm._v(_vm._s(_vm.$t('Cerrar sesión')))])],1):_vm._e(),_vm._v(" "),_c('b-nav-item-dropdown',{staticClass:"d-none d-md-inline",attrs:{"text":_vm.$t('Idioma'),"right":""}},[_c('b-dropdown-item',{attrs:{"to":_vm.switchLocalePath('en')}},[_vm._v("English")]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":_vm.switchLocalePath('es')}},[_vm._v("Español")])],1)],1)],1)],1),_vm._v(" "),_c('b-navbar',{staticClass:"d-flex d-md-none"},[_c('b-navbar-nav',[_c('b-nav-item-dropdown',{staticClass:"link-category",attrs:{"text":_vm.$t('Crea tu producto'),"toggle-class":"nav-link-custom"}},[_c('div',{staticClass:"cats-wrapper"},_vm._l((_vm.onlineMenuTree(null)),function(cat1){return _c('div',{key:cat1.element.id,staticClass:"cat-wrapper"},[_c('b-nav-item',{staticClass:"cat-border-bottom",attrs:{"to":_vm.localePath({
                  name: 'simulator-slug',
                  params: {
                    slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                  },
                })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat1.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subcats-wrapper"},_vm._l((cat1.children),function(cat2){return _c('div',{key:cat2.reference,staticClass:"subcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                      name: 'simulator-slug-pslug',
                      params: {
                        pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                        slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                      },
                    })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat2.element,'reference')))]),_vm._v(" "),_c('div',{staticClass:"subsubcats-wrapper"},_vm._l((cat2.children),function(cat3){return _c('div',{key:cat3.reference,staticClass:"subsubcat-wrapper"},[_c('b-nav-item',{attrs:{"to":_vm.localePath({
                          name: 'simulator-slug-pslug-ppslug',
                          params: {
                            ppslug: _vm.getTranslatedKey(cat3.element, 'slug'),
                            pslug: _vm.getTranslatedKey(cat2.element, 'slug'),
                            slug: _vm.getTranslatedKey(cat1.element, 'slug'),
                          },
                        })}},[_vm._v(_vm._s(_vm._f("getTranslatedKey")(cat3.element,'reference')))])],1)}),0)],1)}),0)],1)}),0)])],1),_vm._v(" "),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item-dropdown',{staticClass:"position-static cart-wrapper",attrs:{"right":"","no-caret":""}},[_c('template',{slot:"button-content"},[_c('b-avatar',{class:[{ addCartEffect: _vm.addedToCartEffectEnabled }],attrs:{"alt":"Cesta","square":"","size":"32px","variant":"default","icon":"handbag","badge-top":"","badge-offset":"-0.5em","badge":_vm.numCartItems}})],1),_vm._v(" "),_c('CartDropdown')],2),_vm._v(" "),_c('b-nav-item-dropdown',{staticClass:"d-none d-md-inline",attrs:{"text":_vm.$t('Idioma'),"right":""}},[_c('b-dropdown-item',{attrs:{"to":_vm.switchLocalePath('en')}},[_vm._v("English")]),_vm._v(" "),_c('b-dropdown-item',{attrs:{"to":_vm.switchLocalePath('es')}},[_vm._v("Español")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }