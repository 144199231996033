<template>
  <div>
    <div v-if="getCartItems.length > 0">
      <!-- <b-col cols="12">{{ getCartItems }} </b-col> -->
      <b-dropdown-item v-for="(v, i) in getCartItems" :key="i">
        <div class="d-flex justify-content-between">
          <div class="ref-label">{{ v.quantity }}x {{ v.reference }}</div>
          <div class="ml-4">{{ v.price.toFixed(2) }} €</div>
        </div>
        <div class="attributes">
          <ul>
            <li :key="j" v-for="(attribute, j) in v.attributes">
              {{ attribute.label }} :
              {{ attribute.selector_inputs.name }}
            </li>
          </ul>
        </div>
      </b-dropdown-item>
      <b-dropdown-text>
        <div class="d-flex justify-content-between">
          <div class="font-weight-bold">{{ $t('TOTAL (IVA incluido)') }}</div>
          <div class="font-weight-bold">{{ totalPrice.toFixed(2) }} €</div>
        </div>
      </b-dropdown-text>
      <b-dropdown-divider></b-dropdown-divider>
      <b-row no-gutters class="text-center">
        <b-col>
          <b-dropdown-item-button
            ><b-button
              variant="outline-secondary"
              :to="localePath({ name: 'cart-token' })"
              block
              >{{ $t('Ver/editar cesta') }}</b-button
            ></b-dropdown-item-button
          ></b-col
        >
        <b-col>
          <b-dropdown-item-button
            ><b-button
              :to="localePath({ name: 'checkout' })"
              variant="primary"
              block
              >{{ $t('Finalizar compra') }}</b-button
            ></b-dropdown-item-button
          ></b-col
        >
      </b-row>
    </div>
    <div v-else>
      <b-dropdown-item>{{
        $t('No hay artículos en la cesta')
      }}</b-dropdown-item>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CartDropdown',
  props: {},
  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      getCartItems: 'cart/getCartItems',
    }),
    totalPrice() {
      let price = 0;
      this.getCartItems.map((x) => {
        price += x.quantity * x.price;
      });
      return price;
    },
  },

  methods: {},

  updated() {
    //this.renderViews();
  },
};
</script>

<style lang="scss" scoped>
.attributes {
  font-size: 0.8rem;
  color: #333;
}
::v-deep.dropdown-item:hover,
::v-deep.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: inherit !important;
  cursor: auto;
}
</style>
