<template>
  <div class="error-500">
    <h1>{{ $t('Ha ocurrido un error inesperado') }}</h1>
    <p>
      {{
        $t(
          'Nuestro equipo ya ha sido notificado y está trabajando en solucionarlo'
        )
      }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'error-500',
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
