import Vue from 'vue';

Vue.mixin({
  methods: {
    getTranslatedKey(element, key) {
      if (typeof element !== 'undefined' && element) {
        return ['en', 'es'].indexOf(this.$i18n.locale) >= 0
          ? element[`${key}_${this.$i18n.locale}`]
          : element[`${key}_${this.$i18n.fallbackLocale}`];
      }
      return '';
    },
  },
});
