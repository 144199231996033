import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ app }) => {
  // const i18n = app.i18n;

  Vue.use(
    VueGtag,
    {
      enabled: true,
      config: { id: app.$config.gtm.id },
    },
    app.router
  );
};
