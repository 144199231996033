import Vue from 'vue';

export default ({ app }) => {
  const i18n = app.i18n;

  Vue.filter('getTranslatedKey', function (element, key) {
    return ['en', 'es'].indexOf(i18n.locale) >= 0
      ? element[`${key}_${i18n.locale}`]
      : element[`${key}_${i18n.fallbackLocale}`];
  });

  Vue.filter('lowercase', function (element) {
    return element.toLowerCase();
  });
};
