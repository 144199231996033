export const state = () => ({
  languages: {
    es: 'Español',
    en: 'English',
  },
});

export const mutations = {};

export const getters = {
  getLanguages(state) {
    return state.languages;
  },
  getLanguageCodes(state) {
    return Object.keys(state.languages).map((k) => k);
  },
  getLanguageNames(state) {
    return Object.keys(state.languages).map((k) => state.languages[k]);
  },
};
