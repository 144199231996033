export const state = () => ({
  overlay: false,
});

export const mutations = {
  setOverlay(state, overlay) {
    state.overlay = overlay;
  },
};

export const getters = {
  getOverlay(state) {
    return state.overlay;
  },
};

export const actions = {};
