<template>
  <div>
    <h2>¿Qué son las cookies?</h2>
    <p>
      Una cookie es un fichero que se descarga en su ordenador al acceder a
      determinadas páginas web. Las cookies permiten a una página web, entre
      otras cosas, almacenar y recuperar información sobre hábitos de navegación
      de un usuario o de su equipo y, dependiendo de la información que
      contengan y de la forma que utilice su equipo, pueden utilizarse para
      reconocer al usuario.
    </p>
    <p>
      Las cookies son esenciales para el funcionamiento de internet, aportando
      innumerables ventajas en la prestación de servicios interactivos,
      facilitándole la navegación y usabilidad de nuestra web. Tenga en cuenta
      que las cookies no pueden dañar su equipo y que, a cambio, el que estén
      activadas nos ayudan a identificar y resolver los errores.
    </p>
    <p>
      La información que le proporcionamos a continuación, le ayudará a
      comprender los diferentes tipos de cookies:
    </p>
    <p>
      _ Las cookies <strong>estrictamente necesarias </strong>como, por ejemplo,
      aquellas que sirvan para una correcta navegación o las que permitan
      realizar servicios solicitados por el usuario o cookies que sirvan para
      asegurar que el contenido de la página web se carga eficazmente.
    </p>
    <p>
      _ Las cookies <strong>analíticas </strong>con propósitos de mantenimiento
      periódico, y en aras de garantizar el mejor servicio posible al usuario,
      los sitios web´s hacen uso normalmente de cookies “analíticas” para
      recopilar datos estadísticos de la actividad.
    </p>
    <p>&nbsp;</p>
    <h2>¿Para qué utiliza las cookies esta página web y cuáles son?</h2>
    <p>
      Esta página web utiliza cookies para una serie de finalidades, incluidas:
    </p>
    <ul>
      <li>
        Análisis: son aquellas cookies que bien, tratadas por nosotros o por
        terceros, nos permiten cuantificar el número de usuarios y así realizar
        la medición y análisis estadístico de la utilización que hacen los
        usuarios del servicio. Para ello se analiza su navegación en nuestra
        página web con el fin de mejorar la experiencia del usuario.
      </li>
    </ul>

    <p>&nbsp;</p>
    <h2>Cookies utilizadas en este sitio web</h2>
    <p>
      Siguiendo las directrices de la Agencia Española de Protección de Datos y
      el Reglamento General de Protección de Datos (RGPD) procedemos a detallar
      el uso de <em>cookies</em> que hace esta web con el fin de informarle con
      la máxima exactitud posible.
    </p>
    <p>
      Este sitio web utiliza las siguientes <strong>cookies propias</strong>:
    </p>
    <ul>
      <li>
        Cookies estrictamente necesarias (técnicas): utilizadas para gestionar
        el consentimiento de las mismas cookies; iniciar y mantener la sesión en
        la web; gestión de la cesta de la compra y sus productos; guardar el
        idioma en que se muestra la web. <br />Nombre de estas cookies:
        cookie_control_consent, cookie_control_enabled_cookies, token y cart.
      </li>
    </ul>
    <p>
      Este sitio web utiliza las siguientes
      <strong>cookies de terceros</strong>:
    </p>
    <ul>
      <li>
        Cookies estrictamente necesarias (Stripe): utilizadas por nuestro
        proveedor de pagos en el momento que el usuario se dispone a realizar
        una compra a través del sitio web.
        <br />Nombre de estas cookies: __stripe_mid, __stripe_sid.
      </li>
      <li>
        Cookies de análisis (Google Analytics): para poder elaborar estadísticas
        sobre el tráfico y volumen de visitas de esta web.
        <br />Nombre de estas cookies: ga, _ga, _gat y _gid.
      </li>
      <li>
        Cookies de análisis (Google Tag Manager): para poder elaborar
        estadísticas sobre el tráfico y volumen de visitas de esta web.
        <br />Nombre de estas cookies: _gcl_au.
      </li>
    </ul>
    <p>&nbsp;</p>
    <h2>¿Cómo puedo desactivar o eliminar estas cookies?</h2>
    <p>
      La primera vez que un usuario accede a este sitio web, se le pide el
      consentimiento total o parcial a la utilización de cookies. Puede volver a
      abrir el diálogo de preferencias, puede hacer
      <b-link @click="openManageCookies()" class="primary-color">
        click aquí
      </b-link>
    </p>
    <p>
      Recuerde que también puede consultar el manual de usuario de su navegador
      web para encontrar más información sobre cómo este gestiona las cookies y
      ver qué herramientas pone a su disposición, por ejemplo, para consultar,
      modificar y/o eliminar las cookies de los sitios web que visita.
    </p>
  </div>
</template>

<script>
export default {
  name: 'CookiePolicyContent',
  methods: {
    openManageCookies() {
      this.$cookies.modal = true;
    },
  },
};
</script>

<style lang="scss" src="@/assets/css/legal.scss" scoped></style>
