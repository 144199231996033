import Vue from 'vue';

import SortControl from '@/components/overrides/vue-tables-2/SortControl.vue';

import { ClientTable } from 'vue-tables-2';

Vue.use(
  ClientTable,
  {
    perPageValues: [],
    texts: {
      filter: '',
      filterPlaceholder: 'Buscar en la tabla',
      count:
        'Mostrando de {from} a {to} de un total de {count} resultados|{count} resultados|Un resultado',
    },
    sortIcon: {
      is: 'arrow-down-up',
      base: '',
      up: 'arrow-up',
      down: 'arrow-down',
    },

    filterable: true,
  },
  false,
  'bootstrap4',
  {
    sortControl: SortControl,
  }
);
