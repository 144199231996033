<template>
  <div class="error-404">
    <h1>{{ $t('Esta página no existe') }}</h1>
    <p>{{ $t('El enlace al que has accedido está roto o ya no existe') }}</p>
  </div>
</template>
<script>
export default {
  name: 'error-404',
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
