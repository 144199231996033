<template>
  <div id="app" class="storeWrapper">
    <Header />

    <b-overlay :fixed="true" no-wrap :show="getOverlay"></b-overlay>
    <transition mode="out-in" @after-leave="afterLeave" name="page">
      <router-view :key="$route.path" class="mainContent"></router-view
    ></transition>

    <notifications position="bottom right" />
    <Footer ref="footer" />
    <div style="visibility: hidden; position: fixed; right: 50">
      <!-- Preloading fonts -->
      <span style="font-family: AR Berkley">A</span>
      <span style="font-family: Arial">A</span>
      <span style="font-family: Bodoni">A</span>
      <span style="font-family: Calibri">A</span>
      <span style="font-family: Castellar">A</span>
      <span style="font-family: Colonna">A</span>
      <span style="font-family: Daddys Hand">A</span>
      <span style="font-family: Ecuyer Dax">A</span>
      <span style="font-family: Edwardian Script">A</span>
      <span style="font-family: English">A</span>
      <span style="font-family: Grotesque">A</span>
      <span style="font-family: London">A</span>
      <span style="font-family: Verdana">A</span>
      <!-- /End preload fonts -->
    </div>
    <SocialChat class="whatsapp-chat" icon :attendants="attendants">
      <p slot="header">
        {{ $t('¿Podemos ayudarte? Click para iniciar un chat en WhatsApp') }}
      </p>
      <template v-slot:button>
        <img src="/imgs/whatsapp.svg" alt="icon whatsapp" aria-hidden="true" />
      </template>
    </SocialChat>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';

import moment from 'moment';
import 'moment/locale/es';

// import { onAnalyticsReady } from 'vue-analytics';

export default {
  name: 'StoreMainWrapper',
  props: {
    msg: String,
  },
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      productsCategoryIDSelected: 0,
      attendants: [
        // ...
      ],
    };
  },

  mounted() {
    // let currentTime = moment.utc();

    // UTC is -2 hours compared to Spain time.

    // let openingStart = moment.utc('08:30:00', 'hh:mm:ss'); // 10:30 Spain
    // let openingEnd = moment.utc('16:30:00', 'hh:mm:ss'); // 18:30 Spain

    // const isOpen = currentTime.isBetween(openingStart, openingEnd);

    this.attendants = [
      {
        app: 'whatsapp',
        label: 'from:BEA',
        name: this.$t('Tienda de Barcelona'),
        number: '34659833811',
        avatar: {
          src: '/imgs/logo_social.jpeg',
          alt: 'from:BEA',
        },
      },
    ];

    let token = this.$cookiez.get('token');
    if (token) {
      this.$actions.getAuth(token).then((x) => {
        if (x.data.auth === false) {
        } else {
          this.setAuth({
            isAuthenticated: true,
            token,
            type: x.data.type,
            business_type: x.data.business_type,
          });

          if (this.$route.name === 'StoreLoginScreen') {
            this.$router.replace({
              name:
                this.$route.params && this.$route.params.redirect /*&&
              this.$route.params.redirect !== 'LoginScreen'*/
                  ? this.$route.params.redirect
                  : 'index',
            });
          }
        }
      });
    }

    let cartCookie = this.$cookiez.get('cart');
    if (cartCookie) {
      this.$actions
        .getCart(cartCookie)
        .then((x) => {
          this.setCartItems(x.data.products);
        })
        .catch(() => {
          this.$cookiez.remove('cart');
        });
    }
  },

  methods: {
    ...mapMutations({
      setAuth: 'auth/setAuth',
      setCartItems: 'cart/setCartItems',
    }),

    afterLeave: function (el) {
      window.$nuxt.$root.$emit('page-after-leave');
    },

    clickedLinkProducts(to) {
      this.$bvModal.hide('modal-1');
      this.$router.push(this.localePath(to));
    },

    clickedCTABtnProducts(i) {
      this.productsCategoryIDSelected = i;
      this.$bvModal.show('modal-1');
    },
  },
  computed: {
    ...mapGetters({
      getOverlay: 'overlay/getOverlay',
    }),
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.mainContent {
  flex: 1;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  // color: #42b983;
}
.storeWrapper {
  display: flex;
  //min-height: 100%;
  // position: relative;
  // padding-bottom: 4rem;
  min-height: 100vh;
  flex-direction: column;
  background-image: var(--bg-image-header-uri);
}

.whatsapp-chat {
  --whatsapp: #46c056;
  --vsc-bg-header: var(--whatsapp);
  --vsc-bg-button: var(--whatsapp);
  --vsc-outline-color: var(--whatsapp);
  --vsc-border-color-bottom-header: #289d37;
}

@media only screen and (max-width: 767px) {
  .storeWrapper {
    // padding-bottom: 6rem;
  }
}
</style>
