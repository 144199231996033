<template>
  <div class="nuxt-error-layout">
    <div>
      <img class="logo" src="@/assets/img/logo-frombea.png" />
    </div>

    <Nuxt />

    <div>
      <p>
        {{ $t('Volver a la') }}
        <b-link :to="localePath({ name: 'index' })">{{
          $t('Página de nicio')
        }}</b-link>
      </p>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'error-layout',
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.nuxt-error-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10vh;
  height: 100vh;
  background-color: #f1eadb;
}
.logo {
  height: 50px;
}
</style>
