<template>
  <span v-if="props.sortable" :class="props.class">
    <b-icon
      :icon="
        props.sortStatus.sorted === false
          ? 'arrow-down-up'
          : props.sortStatus.asc
          ? 'arrow-up'
          : 'arrow-down'
      "
    ></b-icon>
  </span>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';

export default {
  name: 'SortControl',
  props: ['props'],
};
</script>
